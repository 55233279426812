import { useContext } from "react"
import { Context } from "@/context/state"
import { LanguageContext } from "@/language/state"

// import EN from "@/translations/en.json"
// import NL from "@/translations/nl.json"
// import ES from "@/translations/es.json"
// import IT from "@/translations/it.json" // Add missing import statement for IT
// import DE from "@/translations/de.json" // Add import statement for DE
// import FR from "@/translations/fr.json" // Add import statement for FR

export default function translate(value, languageState, router, state) {
  if (state && router && languageState) {
    var lang = state?.locale || router?.locale

    try {
      const translations =
        lang === "en"
          ? languageState.EN
          : lang === "es"
          ? languageState.ES
          : lang === "it"
          ? languageState.IT
          : lang === "nl"
          ? languageState.NL
          : lang === "de"
          ? languageState.DE
          : lang === "fr"
          ? languageState.FR
          : lang === "pt"
          ? languageState.PT
          : languageState.NL

      if (translations[value]) {
        return translations[value]
      } else {
        throw "Key not found"
      }
    } catch (error) {
      throw `Unable to get translation content for ${value}`
    }
  } else {
    const languageState = useContext(LanguageContext)
    const { state } = useContext(Context)

    var lang = state?.locale || router?.locale

    try {
      const translations =
        lang === "en"
          ? languageState.EN
          : lang === "es"
          ? languageState.ES
          : lang === "it"
          ? languageState.IT
          : lang === "nl"
          ? languageState.NL
          : lang === "de"
          ? languageState.DE
          : lang === "fr"
          ? languageState.FR
          : lang === "pt"
          ? languageState.PT
          : languageState.NL

      if (translations[value]) {
        return translations[value]
      } else {
        throw "Key not found"
      }
    } catch (error) {
      throw `Unable to get translation content for ${value}`
    }
  }
}
